<template>
  <router-view />
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import contactsModule from '@/store/main/customer/contacts'

export default {
  name: 'Contacts',
  created() {
    this.$emit('updateMenu', 'home-customers-contacts')
  },
  // beforeRouteLeave(to, from, next) {
  //   const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
  //   if (answer) {
  //     next()
  //   } else {
  //     next(false)
  //   }
  // },

  setup() {
    const MODULE_NAME = 'contacts'
    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, contactsModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })
  },
}
</script>

<style scoped></style>
