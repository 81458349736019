import crudActions from '@/libs/storeConstActions'
import axios from '@/libs/axios'
import store from '@/store'
import { stringify } from 'qs'

const endpoint = () => `customers/${store.state.customers?.customer?.id}/contacts`

const Actions = crudActions(endpoint)

const update = (ctx, data) => axios.put(`/${endpoint()}/${data.get('id')}`, data)
const deleteAttachment = (ctx, { id }) => axios.delete(`/attachments/${id}`)
const updateContacts = (ctx, data) => axios.post(`/${endpoint()}/${data.get('id')}?_method=PUT`, data)
const setDefault = (ctx, data) => axios.post(`/${endpoint()}/${data.id}/set-default`)
const uploadAttachment = (ctx, data) => axios.get(`/attachments/${data.id}`)
const restoreContact = (ctx, data) => axios.post('/customer/contacts/restore', data)
const checkFreeContact = (ctx, data) => axios.post(`/customers/${data.customer_id}/contacts/check-account-holder`, data)
const removeContactTypes = (ctx, data) => axios.post(`/customers/${data.customer_id}/contacts/remove-account-holder`, data)

const getActiveContactsList = ({ commit }, queryParams) => new Promise((resolve, reject) => {
  // eslint-disable-next-line no-param-reassign
  queryParams = { ...queryParams, ...{ is_active: 1 } }
  axios.get(`${endpoint()}`, {
    params: queryParams,
    paramsSerializer: params => stringify(params),
  })
    .then(response => {
      commit('LIST', response.data.data.data)
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})
const getInActiveContactsList = ({ commit }, queryParams) => new Promise((resolve, reject) => {
  // eslint-disable-next-line no-param-reassign
  queryParams = { ...queryParams, ...{ is_active: 0 } }
  axios.get(`${endpoint()}`, {
    params: queryParams,
    paramsSerializer: params => stringify(params),
  })
    .then(response => {
      commit('LIST', response.data.data.data)
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})
const setInActive = (ctx, data) => new Promise((resolve, reject) => {
  // eslint-disable-next-line no-param-reassign
  delete data.attachments
  // eslint-disable-next-line no-param-reassign
  data.is_active = false
  axios.put(`${endpoint()}/${data.id}`, data)
    .then(response => {
      resolve(response)
    }).catch(error => {
      console.log(error)
      reject(error)
    })
})
const setActive = (ctx, data) => new Promise((resolve, reject) => {
  // eslint-disable-next-line no-param-reassign
  delete data.attachments
  // eslint-disable-next-line no-param-reassign
  data.is_active = true
  axios.put(`${endpoint()}/${data.id}`, data)
    .then(response => {
      resolve(response)
    }).catch(error => {
      console.log(error)
      reject(error)
    })
})
export default {
  update,
  setDefault,
  setInActive,
  setActive,
  ...Actions,
  updateContacts,
  deleteAttachment,
  uploadAttachment,
  restoreContact,
  checkFreeContact,
  removeContactTypes,
  getActiveContactsList,
  getInActiveContactsList,
}
