export default {
  GET(state, data) {
    state.contact = { ...data }
  },
  LIST(state, data) {
    state.contacts = data
  },
  SET_CONTACT_FORM(state, data) {
    state.contactsForm = { ...data }
  },
  RESET_CONTACT_FORM(state) {
    state.contactsForm = {}
  },
}
